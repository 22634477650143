import React from 'react';
import { RichText , Date } from 'prismic-reactjs'
import { graphql , Link } from 'gatsby';
import Layout from '../components/layouts';
import KenektHelmet from '../components/KenektHelmet';
import ReactSvg from "react-svg";
import waveBottom from "../images/wave-1-bottom-sm.svg";
import { linkResolver } from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'


// Query for the Blog Home content in Prismic
export const query = graphql`
{
  prismic {
    allHomes(uid: null) {
      edges {
        node {
          _meta {
            id
            uid
            type
            lastPublicationDate
          }
          header
          second_header
          summary
          thumbnail
          description
          services_header
          profile
          services {
            service
          }
          seo_title
          seo_keywords
          seo_description
          social_image
        }
      }
    }
    allSite_meta_datas(uid: null) {
      edges {
        node {
          _meta {
            id
            uid
            type
          }
          phone
          phone_display_text
          email
          admin_email
          city
          body {
            __typename
            ... on PRISMIC_Site_meta_dataBodyMenu {
              fields {
                item_name
                relative_link
              }
            }
          }
          default_keywords
          logo
          copyright
          copyright_link {
            __typename
            ... on PRISMIC__ExternalLink {
              url
            }
          }
        }
      }
    }
  }
  site {
    siteMetadata {
      siteURL
    }
  }
}

`


const ModificationDate = ({date}) => {
    // Store and format the blog post's publication date
    let postDate = Date(date);
    postDate = postDate ?
      new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
      }).format(postDate) : '';

    return (
          <time>{ postDate }</time>
      );

}

const Services = (services) => {
  return <ul className="list-unstyled">
    {services.services.map((service,index) => {
        return <li className="u-indicator-steps py-3" key={index}>
          <div className="media align-items-center border rounded p-5">
            <div className="d-flex u-indicator-steps__inner mr-3">
              <div className="media">
                <span className="btn btn-sm btn-icon btn-soft-primary rounded-circle mr-3"><span className="btn-icon__inner"></span></span>
                <div className="media-body">
                  <h3 className="h5 text-muted">{service.service}</h3>
                </div>
              </div>

            </div>
          </div>
        </li>
    })
    }
    </ul>
}

export default ({ data }) => {
  // Define the Blog Home & Blog Post content returned from Prismic
  const home = data.prismic.allHomes.edges.slice(0,1).pop().node;
  const siteMetadatas = data.prismic.allSite_meta_datas.edges.slice(0,1).pop().node;
  const siteURL = data.site.siteMetadata.siteMetadata;

  if(!home) return null;

  return(
    <Layout>
        <KenektHelmet title={home.seo_title}
        description={home.seo_description}
        siteURL={siteURL}
        keywords={home.seo_keywords}
        image={home.social_image}
        />

        <main id="content" role="main">
          <div id="SVGwave1BottomSMShape" className="position-relative text-center gradient-overlay-half-info-v1 bg-img-hero overflow-hidden space-top-2 space-top-md-2">
            <div className="row justify-content-md-center">
              <div className="col-md-10 col-lg-10 col-xl-10">
                <div className="mb-4">
                  <h1 className="display-4 text-white font-weight-normal mb-3">{home.header}</h1>

                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <span className="text-white-70" >
                        <span className="fas fa-map-marker-alt"></span>
                        &nbsp; {siteMetadatas.city}
                      </span>
                    </li>
                    <li className="list-inline-item">
                      <a className="text-white-70" href={siteMetadatas.email}>
                        <span className="fas fa-globe"></span>
                        &nbsp; {siteMetadatas.email}
                      </a>
                    </li>
                  </ul>

                  <h4 className="display-6 text-white font-weight-normal mb-3" >{home.second_header}</h4>
                  <p className="text-white">{home.summary}</p>
                </div>
                <div className="d-flex justify-content-center align-items-center mb-7">

                  <button type="button"  className="btn btn-m text-primary font-weight-semi-bold btn-white transition-3d-hover">
                    <span className="fas fa-phone mr-2"></span>
                    <a href="tel:+61280021063">{siteMetadatas.phone_display_text}</a>

                  </button>
                </div>

                <div className="u-xl-avatar u-xl-avatar--bordered rounded-circle mx-auto">
                  <img className="img-fluid rounded-circle" src={home.thumbnail.url} alt={home.thumbnail.alt}/>
                </div>
              </div>
            </div>

            <figure className="position-absolute right-0 bottom-0 left-0 z-index-n1">
              <img width="100%" height="140px"  className="js-svg-injector" src={waveBottom} alt="Image Description"
                   data-parent="#SVGwave1BottomSMShape"/>
            </figure>
          </div>
          <div className="container u-cubeportfolio space-2 space-bottom-md-3">


            <div className="container space-top-2 ">
                  <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-9">
                    <h2 className="text-primary display-4">{home.services_header}</h2>
                  </div>

                  <div className="row align-items-lg-center">
                          <div className="col-lg-6 order-lg-2 mb-7 mb-lg-0">
                              <Services services={home.services}/>
                          </div>

                          <div id="SVGhouseAgency" className="col-lg-6 order-lg-1" >
                            <div className="pr-lg-7">
                              <img className="img-fluid rounded mx-auto" src={home.profile.url} alt={home.profile.alt}/>
                            </div>
                          </div>
                        </div>

                </div>
            </div>

            <div className= "container space-top-1 space-top-md-1" >
              <div className="w-lg-80 mx-auto">
                <div className="mb-4">
                  <span className="text-muted"><ModificationDate date={home._meta.lastPublicationDate}/></span>
                </div>

                <div className="mb-5">
                  { RichText.render(home.description, linkResolver, htmlSerializer) }
                </div>

              </div>
            </div>

        </main>

    </Layout>
  )
}
