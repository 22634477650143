import React from 'react';
import {Helmet} from "react-helmet";


export default class KenektHelmet extends React.Component {


  renderImage(image){
      if(image && image.url) {
        return <meta property="og:image" content={image.url} />
      }
  }

  renderImageWidth(image){
      if(image && image.dimensions) {
        return <meta property="og:image:width" content={image.dimensions.width} />
      }
  }

  renderImageHeight(image){
      if(image && image.dimensions) {
        return <meta property="og:image:height" content={image.dimensions.height} />
      }
  }

  render() {
    return (
      <Helmet>
         <link rel="canonical" href={this.props.siteURL} />
         <title>{this.props.title}</title>
         <meta name="description" content={this.props.description}/>
         <meta name="keywords" content={this.props.keywords}/>

         <meta property="og:type" content="website"/>
         <meta property="og:url" content={this.props.siteURL}/>
         <meta property="og:title" content={this.props.title}/>
         <meta property="og:site_name" content={this.props.title}/>
         <meta property="og:description" content={this.props.description}/>

         {this.renderImage(this.props.image)}
         {this.renderImageWidth(this.props.image)}
         {this.renderImageHeight(this.props.image)}

         <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
         <meta name="robots" CONTENT="all" />

      </Helmet>
    );
  }
}
